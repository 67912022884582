
































import defaultComponent from "../utils/defaultComponent";

export default defaultComponent.apply({
  props: [],
  data() {
    return {
      titleHTML: "",
      contentHTML: "",
    };
  },
  watch: {
    model: {
      handler(val) {
        if (val && val.value) {
          this.updateContext();
        } else if (val) {
          this.$emit("close");
        }
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      this.updateContext();
    },
    updateContext() {
      switch (typeof this.options.content.title.html) {
        case "function":
          this.titleHTML = this.options.content.title.html(this);
          break;
        default:
          this.titleHTML = this.options.content.title.html;
      }

      switch (typeof this.options.content.content.html) {
        case "function":
          this.contentHTML = this.options.content.content.html(this);
          break;
        default:
          this.contentHTML = this.options.content.content.html;
      }
    },
    show() {
      this.model.value = true;
    },
    hide() {
      this.model.value = false;
    },
  },
  extraOptions: {
    forwardStates: {
      xModel: ["model", false],
    },
    defaultClass: "notifyDialog",
  },
});
